import React from "react"

import { PageProps } from "gatsby"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import SEO from "../components/seo"

import ImageDisplayText from "../components/image-display-text"
import { GridContainer } from "../components/grid"
import { MainHero } from "../components/heros"
import { MainCard } from "../components/cards"
import { USP, USPContainer } from "../components/usp"
import { H2 } from "../components/text"
import { BaseContainer, VideoContainer } from "../components/containers"
import { Clients } from "../components/clients"
import { OnlineAcademyCTA } from "../components/ctas"

import Free from "../svg/free.svg"
import Growth from "../svg/growth.svg"
import Talent from "../svg/talent.svg"

const IntroContainer = styled(BaseContainer)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 2 / span 10;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-column: 3 / span 8;
  }
`

const ContentContainer = styled.div`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(8, 1fr);
    margin-bottom: 8rem;
  }
`

const IntroTitle = styled(H2)`
  margin-bottom: 1rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 1 / span 3;
    margin-bottom: 0;
  }
`

const Paragraph = styled.p`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 4 / span 5;
  }
`

const StyledVideoContainer = styled(VideoContainer)`
  margin-top: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-top: 0;
  }
`

const Intro: React.FC = () => (
  <IntroContainer>
    <ContentContainer>
      <IntroTitle>Wat doen wij?</IntroTitle>
      <Paragraph>
        Wij zijn een collectief van vrij denkers, creatievelingen en vakidioten
        die aan elkaar verbonden zijn door een gemeenschappelijke visie op leren
        en organiseren. Al meer dan 20 jaar begeleiden wij organisaties, teams,
        en professionals met complexe vraagstukken. Van organisatieontwerp tot
        coaching; als je kiest voor samenwerken met ons dan kies je voor een
        persoonlijke, confronterende en integrale aanpak. Dat is wat ons
        onderscheidt.
      </Paragraph>
    </ContentContainer>
    <StyledVideoContainer
      title="De Beukelaar Groep - Onze visie"
      src="https://www.youtube.com/embed/2FHbSLEu4_c?autoplay=0&amp;mute=0"
    />
  </IntroContainer>
)

const USPs: React.FC = () => (
  <USPContainer>
    <USP icon={<Free />} title="Vrijdenkers">
      Elke organisatie- vraagstuk verdient een unieke aanpak
    </USP>
    <USP icon={<Growth />} title="Professionele Groei">
      Geïnspireerd en sterker in je werk en leven
    </USP>
    <USP icon={<Talent />} title="Talentgericht">
      Meer eigenaarschap, initiatief en positieve energie
    </USP>
  </USPContainer>
)

const ServicesContainer = styled(BaseContainer)`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 2 / span 10;
  }
`

const ItemsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;

  display: grid;
  grid-template-columns: repeat(1, 1fr);

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: 2 / span 10;
    grid-template-columns: repeat(2, 1fr);
  }
`

const Title = styled(H2)`
  margin-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-bottom: 4rem;
  }
`

const ServicesCards: React.FC = () => (
  <ServicesContainer>
    <Title>Diensten</Title>
    <ItemsContainer>
      <MainCard
        to="/diensten/organisatieontwikkeling/"
        title="Organisatieontwikkeling"
      >
        Het groeipotentieel van een organisatie benutten door zowel structuur,
        de mens in de organisatie en de cultuur te ontwikkelen, om zo de interne
        en externe uitdagingen aan te kunnen.
      </MainCard>
      <MainCard to="/diensten/teamontwikkeling/" title="Teamontwikkeling">
        Kwaliteit, productiviteit en plezier in teams vergroten door betere
        samenwerking en inzicht in elkaars talenten.
      </MainCard>
      <MainCard to="/diensten/leiderschap/" title="Leiderschap">
        Leiderschap begint met het leiden van jezelf. Pas dan kun je werkelijk
        leidinggeven aan een team en organisatie.
      </MainCard>
      <MainCard
        to="/diensten/diversiteit-en-inclusie/"
        title="Diversiteit &amp; Inclusie"
      >
        Een inclusieve organisatie is een organisatie waar je jezelf kunt zijn
        en waar je erbij hoort. Zo’n organisatie zal beter functioneren omdat
        hier de ruimte is voor verschillende inzichten en talenten.
      </MainCard>
    </ItemsContainer>
  </ServicesContainer>
)

const ImagesContainer = styled(BaseContainer)`
  display: grid;
  gap: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Images: React.FC = () => (
  <ImagesContainer>
    <StaticImage
      src="../images/jeremy-bishop-growing.jpg"
      alt="Groeien"
      placeholder="blurred"
    />
    <StaticImage
      src="../images/mieneke-boek-lezen.jpg"
      alt="Mieneke is een boek aan het lezen"
      placeholder="blurred"
    />
  </ImagesContainer>
)

const IndexPage: React.FC<PageProps> = () => (
  <>
    <SEO
      title="Home"
      description="Al meer dan 20 jaar begeleiden wij organisaties, teams, en professionals met complexe vraagstukken. Van organisatieontwerp tot coaching; als je kiest voor samenwerken met ons dan kies je voor een persoonlijke, confronterende en integrale aanpak. Dat is wat ons onderscheidt."
    />
    <GridContainer>
      <MainHero
        title="Investeren in je medewerkers, is investeren in je organisatie"
        subtitle="Ben je klaar om jezelf, je team en organisatie echt te verbeteren?"
      />
      <Intro />
      <USPs />
      <ServicesCards />
      <ImageDisplayText
        image={
          <StaticImage
            src="../images/de-beukelaar-groepsfoto.jpg"
            alt="Groepsfoto van de leden van De Beukelaar Groep"
            placeholder="blurred"
          />
        }
        text="Met het hoofd, het hart en de handen helpen wij jou verder"
      />
      <Clients title="Onze klanten" />
      <Images />
      <OnlineAcademyCTA />
    </GridContainer>
  </>
)

export default IndexPage
