import React, { ReactNode } from "react"
// import Carousel from "react-elastic-carousel"
import Carousel from "react-slick"
import styled from "styled-components"

import "./styles/slick.scss"
import "./styles/slick-theme.scss"

const CarouselContainer = styled(Carousel)`
  background: ${props => props.theme.colors.white};
  padding: 1rem;
  cursor: pointer;
  width: 100%;

  .rec-dot {
    box-shadow: none;
    background: ${props => props.theme.colors.grey};

    &_active {
      background: ${props => props.theme.colors.beukelaarGreen};
    }
  }

  .slick-dots {
    margin-top: 2rem;
    display: flex !important;
    align-items: center;
    justify-content: center;
    position: unset;

    li,
    button {
      width: 0.5rem;
      height: 0.5rem;
    }

    li {
      box-shadow: none;
      border-radius: 50%;
      background: ${props => props.theme.colors.grey};

      button:before {
        content: "";
      }

      &.slick-active {
        background: ${props => props.theme.colors.beukelaarGreen};
      }
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 2rem;
    border-radius: 12px;
  }
`

interface SliderProps {
  children: ReactNode
  className?: string
  slidesToShow?: number
  slidesToScroll?: number
  enableAutoPlay?: boolean
  autoPlaySpeed?: number
  showDots?: boolean
  adaptiveHeight?: boolean
  arrows?: boolean
  centerMode?: boolean
  responsive?: any[]
}

const Slider: React.FC<SliderProps> = ({
  children,
  className,
  slidesToShow = 1,
  slidesToScroll = 1,
  enableAutoPlay = false,
  autoPlaySpeed = 3000,
  adaptiveHeight = false,
  arrows = false,
  showDots = true,
  centerMode = false,
  responsive = [],
}) => (
  <CarouselContainer
    slidesToShow={slidesToShow}
    slidesToScroll={slidesToScroll}
    arrows={arrows}
    adaptiveHeight={adaptiveHeight}
    className={className}
    autoplay={enableAutoPlay}
    autoplaySpeed={autoPlaySpeed}
    centerMode={centerMode}
    dots={showDots}
    responsive={responsive}
  >
    {children}
  </CarouselContainer>
)

export default Slider
