import React, { ReactNode } from "react"
import styled from "styled-components"

import { BaseContainer } from "../containers"

const Container = styled(BaseContainer)`
  display: flex;
  flex-direction: column;

  > * + * {
    margin: 4rem 0 0 0;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    flex-direction: row;
    justify-content: space-between;

    > * + * {
      margin: 0 0 0 4rem;
    }
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    grid-column: 2 / span 10;
  }
`

interface USPContainerProps {
  children: ReactNode
  className?: string
}

const USPContainer: React.FC<USPContainerProps> = ({ className, children }) => (
  <Container className={className}>{children}</Container>
)

export default USPContainer
