import React, { ReactNode } from "react"
import styled from "styled-components"

import { H4 } from "../text"

const Container = styled.div`
  text-align: center;
`

const ImageContainer = styled.div`
  margin: auto;
  margin-bottom: 1.5rem;
  max-width: 64px;
`

const Title = styled(H4)`
  margin-bottom: 1.5rem;
`

const Paragraph = styled.p`
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    max-width: 28ch;
  }
`

interface USPProps {
  icon: ReactNode
  title: string
  children: ReactNode
  className?: string
}

const USP: React.FC<USPProps> = ({ icon, title, children, className }) => (
  <Container className={className}>
    <ImageContainer>{icon}</ImageContainer>
    <Title>{title}</Title>
    <Paragraph>{children}</Paragraph>
  </Container>
)

export default USP
