import React, { ReactNode } from "react"
import styled from "styled-components"
import { BaseContainer } from "./containers"

interface ImageDisplayTextProps {
  image: ReactNode
  text: string
}

const GroupPhotoContainer = styled(BaseContainer)`
  position: relative;
  overflow: hidden;
`

const GroupPhotoTextContainer = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;

  padding: 1rem;

  font-family: inherit;
  font-style: normal;
  font-weight: 900;
  font-size: min(8vw, 64px);
  line-height: min(10vw, 80px);
  color: ${({ theme }) => theme.colors.white};

  display: flex;
  align-items: center;

  text-align: right;
  letter-spacing: 1px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 2rem;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    max-width: 40%;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    max-width: 35%;
    padding: 6rem;
  }
`

const ImageDisplayText: React.FC<ImageDisplayTextProps> = ({ image, text }) => (
  <GroupPhotoContainer>
    {image}
    <GroupPhotoTextContainer>
      <p>{text}</p>
    </GroupPhotoTextContainer>
  </GroupPhotoContainer>
)

export default ImageDisplayText
