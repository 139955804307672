import React, { useContext } from "react"
import styled, { ThemeContext } from "styled-components"

import { BaseContainer } from "../containers"
import { H2 } from "../text"
import { Slide, Slider } from "../slider"

import ClientLogo from "./client-logo"

import Aegon from "../../svg/clients/aegon.svg"
import Bartimeus from "../../svg/clients/bartimeus.svg"
import Gansewinkel from "../../svg/clients/gansewinkel.svg"
import GemeenteAmsterdam from "../../svg/clients/gemeente-amsterdam.svg"
import Heerenloo from "../../svg/clients/heerenloo.svg"
import Heineken from "../../svg/clients/heineken.svg"
import ING from "../../svg/clients/ing.svg"
import KIWA from "../../svg/clients/kiwa.svg"
import Leiden from "../../svg/clients/leiden.svg"
import Maatvast from "../../svg/clients/maatvast.svg"
import NovoNordisk from "../../svg/clients/novo-nordisk.svg"
import NS from "../../svg/clients/ns.svg"
import Oce from "../../svg/clients/oce.svg"
import ProvincieNoordHolland from "../../svg/clients/provincie-noord-holland.svg"
import OudePastorie from "../../svg/clients/oudepastorie.svg"

const Container = styled(BaseContainer)`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`

const LogoSlider = styled(Slider)`
  grid-column: 1 / span 12;
  background: ${({ theme }) => theme.colors.grey};
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    padding-bottom: 16rem;
    border-radius: 12px;
  }
`

const TestimonialSlider = styled(Slider)`
  grid-column: 1 / span 12;
  border-top-left-radius: 0;
  border-top-right-radius: 0;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}) {
    grid-column: 3 / span 8;
    border-radius: 12px;
    margin-top: -14rem;
  }
`

const Title = styled(H2)`
  grid-column: 1 / span 12;
  margin-bottom: 2rem;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    text-align: center;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-bottom: 4rem;
  }
`

const Logos: React.FC = () => {
  const themeContext = useContext(ThemeContext)
  const tabletBreakpoint = Number.parseInt(themeContext.breakpoints.tablet)
  const laptopBreakpoint = Number.parseInt(themeContext.breakpoints.laptop)
  const desktopBreakpoint = Number.parseInt(themeContext.breakpoints.desktop)

  return (
    <LogoSlider
      enableAutoPlay={true}
      showDots={false}
      slidesToShow={6}
      slidesToScroll={1}
      responsive={[
        {
          breakpoint: 320,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: 425,
          settings: {
            slidesToShow: 1,
          },
        },
        {
          breakpoint: tabletBreakpoint,
          settings: {
            slidesToShow: 2,
          },
        },
        {
          breakpoint: laptopBreakpoint,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: desktopBreakpoint,
          settings: {
            slidesToShow: 4,
          },
        },
      ]}
    >
      <ClientLogo>
        <Aegon />
      </ClientLogo>
      <ClientLogo>
        <OudePastorie />
      </ClientLogo>
      <ClientLogo>
        <Bartimeus />
      </ClientLogo>
      <ClientLogo>
        <Gansewinkel />
      </ClientLogo>
      <ClientLogo>
        <GemeenteAmsterdam />
      </ClientLogo>
      <ClientLogo>
        <Heerenloo />
      </ClientLogo>
      <ClientLogo>
        <Heineken />
      </ClientLogo>
      <ClientLogo>
        <ING />
      </ClientLogo>
      <ClientLogo>
        <KIWA />
      </ClientLogo>
      <ClientLogo>
        <Leiden />
      </ClientLogo>
      <ClientLogo>
        <Maatvast />
      </ClientLogo>
      <ClientLogo>
        <NovoNordisk />
      </ClientLogo>
      <ClientLogo>
        <NS />
      </ClientLogo>
      <ClientLogo>
        <Oce />
      </ClientLogo>
      <ClientLogo>
        <ProvincieNoordHolland />
      </ClientLogo>
    </LogoSlider>
  )
}

interface ClientsProps {
  className?: string
  title?: string
}

const Clients: React.FC<ClientsProps> = ({ className, title }) => (
  <Container className={className}>
    {title && <Title>{title}</Title>}
    <Logos />
    <TestimonialSlider adaptiveHeight>
      <Slide
        title="Senior Manager - Rabobank"
        subtitle="Een bijzondere vorm van leren en reflecteren is het mentorschap dat we binnen de Rabobank steeds meer en beter weten in te zetten voor de groei en ontwikkeling van onze managers. Ervaren managers begeleiden en ondersteunen minder ervaren managers in de ontwikkeling. Hierbij is het motto: ga niet op je kennis en ervaring zitten: deel het uit. Hoe meer je uitdeelt, hoe meer je terug krijgt. Bijvoorbeeld in de vorm van feedback. Niet alleen minder ervaren managers leren in dit mentorschap, ook ik als mentor-Manager."
      />
      <Slide
        title="HR Manager - ’s Heeren Loo"
        subtitle="Investeren in de teamontwikkeling is voor mij nu de basis van leidinggeven. Ik ben onderdeel van het team en geniet ervan om met het team te ervaren dat we meer resultaten boeken wanneer we meer stil staan bij de samenwerking en ieders rol in het team. De begeleiding van De Beukelaar Groep, die met niet gebruikelijke werkvormen mij en mijn team weten te raken in het hart en te inspireren in het hoofd."
      />
      <Slide
        title="Hoofd Personeels- en organisatieontwikkeling - Havenbedrijf Rotterdam"
        subtitle="De vaardigheid om te allen tijde je verbeeldingskracht en uitvoeringsdiscipline in te zetten om anderen mee te nemen in datgene waar je in gelooft en gerealiseerd wilt krijgen. Met energie en ruimte voor betere inzichten."
      />
      <Slide
        title="Senior Manager - Novo Nordisk"
        subtitle="Een onderdeel van de organisatieontwikkeling was het meer aansturen op openheid en duidelijkheid. Wees open en duidelijk over de plek van de verantwoordelijkheden. De Beukelaar Groep is in deze begeleiding altijd de waakhond voor deze balans."
      />
      <Slide
        title="Projectleider - Reinaerde"
        subtitle="We hebben van De Beukelaar Groep geleerd dat teamontwikkeling iets is dat je moet onderhouden, een proces dat je samen vormgeeft en waar je aandacht voor blijft houden. Zo krijg je een team dat continue blijft leren en verbeteren."
      />
      <Slide
        title="Senior Manager - Rabobank"
        subtitle="De Beukelaar Groep ontwikkelde samen met de Rabobank een leiderschapstraject waarbij het gevestigde Management zo veel mogelijk zelf aan zet was. Zo ontstond ruimte om als Management verantwoordelijkheid te nemen voor het eigen gedrag en de cultuur binnen de afdeling. De Beukelaar Groep ontwierp en faciliteerde dit leerproces van intensieve Leiderschapsontwikkeling bij de Rabo Bank."
      />
      <Slide
        title="Senior Manager - Novo Nordisk"
        subtitle="Organisatieontwikkeling vindt plaatst als het management en bestuur in hun gedrag het voorbeeld geeft. Dus naast coaching op verschillende niveaus door De Beukelaar Groep, hebben we ook een leiderschapstraject opgestart op maat en in lijn van onze visie en missie."
      />
    </TestimonialSlider>
  </Container>
)

export default Clients
