import React from "react"
import styled from "styled-components"

import { H4, Subtitle } from "../text"

import Quotes from "../../svg/quotes.svg"

const SlideContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
`

const IllustrationContainer = styled.div`
  grid-column: 1 / span 12;
  margin-bottom: 2rem;
  width: 64px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: 1 / span 2;
    margin-bottom: 0;
    width: auto;
  }
`

const ContentContainer = styled.div`
  grid-column: 1 / span 12;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    grid-column: 4 / span 9;
  }
`

const Author = styled(H4)`
  margin-top: 2rem;
`
interface SlideProps {
  title: string
  subtitle: string
  className?: string
}

const Slide: React.FC<SlideProps> = ({ title, subtitle, className }) => (
  <SlideContainer className={className}>
    <IllustrationContainer>
      <Quotes />
    </IllustrationContainer>
    <ContentContainer>
      <p>{subtitle}</p>
      <Author>{title}</Author>
    </ContentContainer>
  </SlideContainer>
)

export default Slide
