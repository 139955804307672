import React from "react"
import styled from "styled-components"

const LogoContainer = styled.div`
  width: 10rem;
  margin: auto;

  svg {
    width: 100%;
    height: 100%;
  }
`

interface ClientLogoProps {
  className?: string
}

const ClientLogo: React.FC<ClientLogoProps> = ({ className, children }) => (
  <LogoContainer className={className}>{children}</LogoContainer>
)

export default ClientLogo
